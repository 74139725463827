@import "../vendor/bootstrap/less/bootstrap";
@import "../vendor/font-awesome/less/font-awesome";
@import "fonts";
@import "variables";
@import "../vendor/jasny-bootstrap/less/jasny-bootstrap";

@navmenu-width: 		100%;
@navmenu-default-bg:	#f3f3f3;
@navmenu-default-border: #e6e6e6;
@navmenu-default-color: #25263d;
@navmenu-default-link-color: #25263d;

@navmenu-default-link-hover-color: #ffffff;
@navmenu-default-link-hover-bg: #426785;

@navmenu-default-link-active-color: #25263d;
@navmenu-default-link-active-bg: transparent;

// maintain desired font appearance
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// html {
// 	overflow-x: hidden;
// }

.container {
	max-width: 100%;
}

@import "social_wall";
@import "unitegallery";

.fa-home { line-height: 0 !important;}

.res {
	max-width: 100%;
	height: auto;
}
.col-sm-6.27795 {
	clear: left;
}
*:focus {
	outline: none !important;
}

@media  (min-width: @screen-xs) and (max-width: @screen-xs-max) {
	.col-xs-sm-6 {
		float: left;
		width: percentage((6 / @grid-columns));
	}

	.xs-clearfix {
		.clearfix;
	}
}

.img-responsive {
	width: 100%;
}

.ir {
	display: none;
	border: 0;
	text-indent: -999em;
	overflow: hidden;
	background-color: transparent;
	background-repeat: no-repeat;
	text-align: left;
	direction: ltr;
}

.ir br { display: none; }
.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }
.invisible { visibility: hidden; }

.img-responsive {
	width: 100%;
}

.mw-100 {
	max-width: 100%;
}

.valign-middle {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}

.position-relative {
	position: relative;
}

.my-auto { margin-top: auto !important; margin-bottom: auto !important; }
.mx-auto { margin-left: auto !important; margin-right: auto !important; }
.mt-auto { margin-top: auto !important; }
.mb-auto { margin-bottom: auto !important; }
.ml-auto { margin-left: auto !important; }
.mr-auto { margin-right: auto !important; }
.mt-0 { margin-top: 0 !important; }
.mt-1 { margin-top: 15px !important; }
.mt-2 { margin-top: 30px !important; }
.mt-3 { margin-top: 45px !important; }
.mb-0 { margin-bottom: 0 !important; }
.mb-1 { margin-bottom: 15px !important; }
.mb-2 { margin-bottom: 30px !important; }
.mb-3 { margin-bottom: 45px !important; }

.pt-0 { padding-top: 0 !important; }
.pt-1 { padding-top: 15px !important; }
.pt-2 { padding-top: 30px !important; }
.pt-3 { padding-top: 45px !important; }
.pb-0 { padding-bottom: 0 !important; }
.pb-1 { padding-bottom: 15px !important; }
.pb-2 { padding-bottom: 30px !important; }
.pb-3 { padding-bottom: 45px !important; }
.px-0 { padding-bottom: 0; }
.px-1 { padding-left: 15px !important; padding-right: 15px !important; }
.px-2 { padding-left: 30px !important; padding-right: 30px !important; }
.px-3 { padding-left: 45px !important; padding-right: 45px !important; }
.px-0 { padding-left: 0 !important; padding-right: 0 !important; }
.px-1 { padding-left: 15px !important; padding-right: 15px !important; }
.px-2 { padding-left: 30px !important; padding-right: 30px !important; }
.px-3 { padding-left: 45px !important; padding-right: 45px !important; }

@media (min-width: 1280px) {
	.mt-0 { margin-top: 0 !important; }
	.mt-1 { margin-top: @line-height-computed * 0.75px !important; }
	.mt-2 { margin-top: @line-height-computed * 2px !important; }
	.mt-3 { margin-top: @line-height-computed * 3px !important; }
	.mb-0 { margin-bottom: 0 !important; }
	.mb-1 { margin-bottom: @line-height-computed * 0.75px !important; }
	.mb-2 { margin-bottom: @line-height-computed * 2px !important; }
	.mb-3 { margin-bottom: @line-height-computed * 3px !important; }

	.pt-0 { padding-top: 0 !important; }
	.pt-1 { padding-top: @line-height-computed * 0.75px !important; }
	.pt-2 { padding-top: @line-height-computed * 2px !important; }
	.pt-3 { padding-top: @line-height-computed * 3px !important; }
	.pb-0 { padding-bottom: 0 !important; }
	.pb-1 { padding-bottom: @line-height-computed * 0.75px !important; }
	.pb-2 { padding-bottom: @line-height-computed * 2px !important; }
	.pb-3 { padding-bottom: @line-height-computed * 3px !important; }
	.px-0 { padding-bottom: 0; }
	.px-1 { padding-left: @line-height-computed * 0.75px !important; padding-right: @line-height-computed * 0.75px !important; }
	.px-2 { padding-left: @line-height-computed * 2px !important; padding-right: @line-height-computed * 2px !important; }
	.px-3 { padding-left: @line-height-computed * 3px !important; padding-right: @line-height-computed * 3px !important; }
	.px-0 { padding-left: 0 !important; padding-right: 0 !important; }
	.px-1 { padding-left: @line-height-computed * 0.75px !important; padding-right: @line-height-computed * 0.75px !important; }
	.px-2 { padding-left: @line-height-computed * 2px !important; padding-right: @line-height-computed * 2px !important; }
	.px-3 { padding-left: @line-height-computed * 3px !important; padding-right: @line-height-computed * 3px !important; }

	.pr-md-1 { padding-right: @line-height-computed * 1px !important; }
	.pr-md-2 { padding-right: @line-height-computed * 2px !important; }
	.pr-md-3 { padding-right: @line-height-computed * 3px !important; }
}

.mx-auto {
	margin-left: auto;
	margin-right: auto;
	float: none;
}

p {
	margin-bottom: 15px !important;
}

// Floats
.float-right {
	float: right;
	margin: 0 0 @line-height-computed @line-height-computed;
	clear: right; // This ensures images don't 'bump' into each other
}

.float-left {
	float: left;
	margin: 0 @line-height-computed @line-height-computed 0;
	clear: left; // This ensures images don't 'bump' into each other
}

.float-center {
	float: none;
    margin: 0 auto;
}

.float-none {
	float: none; // if anyone knows why these are "important", let me know
	margin: 0;
}

// Border radius
.rounded-circle {
	border-radius: 100% !important;
	overflow: hidden;
}

.rounded {
	border-radius: 3px !important;
}

.rounded-0 {
	border-radius: 0 !important;
}

// Useful for image link hover states
.transparent (@filter-opacity, @opacity) {
	zoom: 1;
	filter: alpha(opacity=@filter-opacity);
	opacity: @opacity;
}

.btn {
	border-radius: 0;
	padding: 12px 45px;
	font-weight: 600;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus {
	background-color: darken(@stp-blue, 10%) !important;
	color: #fff !important;
}

.border {
	border: 1px solid #eee;
}

/**	----------------------------------------
/**	Environment Indicator
/**	----------------------------------------*/
.env-indicator {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 102;
	color: @white;
	border-radius: 5px 0 0 0;
	padding: 2px 5px;
	font-size: 11px;

	&:not(.env-dev):not(.env-staging) {
		display: none;
	}
}

.env-indicator.env-dev {
	background: @state-success-text;
}

.env-indicator.env-staging {
	background: @state-warning-text;
}


// Grids with 1px borders between them, Ryan loves using these.
.col-bdr, .col-bdl, .col-bdt, .col-bdb {}
.col-bdr:after,
.col-bdl:before,
.col-bdt:before,
.col-bdb:after {
  content:"";
  position: absolute;
  line-height: 1px;
  border: 0px solid @stp-bluegray-lighter;
}

.col-bdr:after,
.col-bdl:before  {
  height: 100%;
}

.col-bdb:after,
.col-bdt:before  {
  width: 100%;
}

.col-bdr:after {
  right: 0px;
  top: 0px;
  border-right-width: 1px;
}

.col-bdl:before {
  left: 0px;
  border-left-width: 1px;
}

.col-bdb:after {
  bottom: 0px;
  border-bottom-width: 1px;
}

.col-bdt:before {
  top: 0px;
  border-top-width: 1px;
}

.btn-edit-this {
	font-size: @font-size-base !important;
	z-index: 5000;
	
	.btn;
	.btn-default;
	.fa;
	padding: 5px 6px;

	&:before {
		content: @fa-var-pencil;
	}

	position: absolute;
	top: 0px;
	right: 0px;
}

/**	----------------------------------------
/** STRUCTURE
/**	1. Layout & Structure
/**	2. Modular components
/**	3. Page Specific
/**	----------------------------------------

/**	----------------------------------------
/**	3. LAYOUT & STRUCTURE
/** Page structures & layout
/**	----------------------------------------*/
html,body {
  height: 100%;
  position: relative;
}

.affix {
	z-index: 9000;
}

.my-affix {

}

@media (min-width: @screen-md) {
  .my-affix.sticky {
    position: fixed;
    top: 20px;
    z-index: 100;

  }

  .my-affix.static {
    position: relative;
    max-width: 100%;
     z-index: 100;
  }
}



#openDay {
	// position: static;
    position: fixed;

   @media (max-width: @screen-sm) {
		margin-top: 10px;
   }

    @media (min-width: @screen-sm) {
    	&.affix, &.affix-top {
    		position: fixed !important;
    		right: 0px;
    		top: 70px;
    		width: auto;
    		overflow: hidden;
    		z-index: 100;

    		@media (min-width: @screen-md) {
    			top: 190px;
    		}
    	}
    }
	

	ul {
		padding: 0;
		margin: 0;

		li {
			list-style: none;
			padding: 0;
			margin: 0; 

			a {
				display: block;
				padding: 10px;
				line-height: 1;
				color: @white;
				text-align: center;
				text-transform: uppercase;
				font-weight: bold;

				&:hover, &:focus {
					text-decoration: none;
				}

				i {
					font-size: 22px;
				}

				&.home {
					background-color: @stp-blue;
					&:hover, &:focus {
						background-color: darken(@stp-bluegray-light, 10%)
					}
				}

				&.share {
					background-color: @stp-bluegray-light;
					&:hover, &:focus {
						background-color: darken(@stp-bluegray-light, 10%);
					}
				}

				&.girls {
					background-color: @stp-red;
					&:hover, &:focus {
						background-color: @stp-red;
					}
				}

				&.boys {
					background-color: @stp-navy;
					&:hover, &:focus {
						background-color: darken(@stp-navy, 10%);
					}
				}
				&.college {
					background-color: @stp-yellow;
					&:hover, &:focus {
						background-color: darken(@stp-yellow, 10%);
					}
				}

				&.foundation {
					background-color: @gray-light;
					&:hover, &:focus {
						background-color: darken(@gray-light, 10%);
					}
				}

				&.college, &.foundation {
					line-height: 2;
				}

				&.girls, &.boys, &.college, &.foundation {
					height: 100px;

					span {
						width: 77px;
						height: 77px;
						font-size: 12px;
						display: inline-block;
						-webkit-transform: rotate(-90deg); /* Safari */
						-moz-transform: rotate(-90deg); /* Firefox */
						-ms-transform: rotate(-90deg); /* IE */
						-o-transform: rotate(-90deg); /* Opera */
						filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); /* Internet Explorer */
					}
					
				}
			}
		}
	}

}

#site-switcher {
	position: absolute;
	left: auto !important;
	right: 100%;
  	top: 8px;
	width: 45px;
	overflow: hidden;
	display: none;

	ul {
		padding: 0;
		margin: 0;

		li {
			list-style: none;
			padding: 0;
			margin: 0; 
			text-align: center;

			a {
				display: block;
				padding: 10px;
				line-height: 1;
				color: @white;
				text-align: center;
				text-transform: uppercase;
				font-weight: bold;

				i {
					font-size: 22px;
				}

				&.home {
					background-color: @stp-blue;
					padding: 15px 10px;
				}
				&.share {
					background-color: @stp-bluegray-light;
				}

				&.girls, &.boys, &.college, &.foundation {
					height: 100px;
					text-align: center;
					span {
						width: 80px;
						height: 80px;
						margin: auto;
						text-align: center;
						font-size: 11px;
						display: block;
						-webkit-transform: rotate(-90deg); /* Safari */
						-moz-transform: rotate(-90deg); /* Firefox */
						-ms-transform: rotate(-90deg); /* IE */
						-o-transform: rotate(-90deg); /* Opera */
						filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); /* Internet Explorer */
					}
					
				}

				&.girls {
					background-color: @stp-red;
				}
				&.boys {
					background-color: @stp-navy;
				}
				&.college {
					background-color: @stp-yellow;
					line-height: 2;
				}
				&.foundation {
					background-color: #999;
					line-height: 2;
				}
			}
		}
	}

	/* Small devices (tablets, 768px and up) */
	@media (min-width: @screen-sm) {

	}

	/* Medium devices (desktops, 992px and up) */
	@media (min-width: @screen-md) {
		display: block;
	}

	/* Large devices (large desktops, 1200px and up) */
	@media (min-width: @screen-lg) {

	}
}

#wrap {
	position: relative;
	min-height: 100%;
	height: auto;
	/* Negative indent footer by its height */
	margin: 0 auto;
	/* Pad bottom by footer height */
	padding: 0 0;
}

.myNavMenuCanvas{
	position: relative;
	top: 0;
	left: 0;
}

.navmenu-fixed-right {
	position: relative;
	z-index: 1000;
	// transform: translate(100%, 0);
}

.wrap {
	position: relative;
	min-height: 100%;
	height: auto;
	/* Negative indent footer by its height */
	margin: 0 auto;
	/* Pad bottom by footer height */
	padding: 0 0;

	
}

#search-pane {
	background-color: #f3f3f3;

	.btn {
		padding: 7px 12px;
	}
}

#alert-pane {
	background-color: @stp-yellow;
	color: @black;
	text-align: center;
	padding: 15px;

	.alert-title {
		font-size: 21px;
		font-weight: 600;
		margin: 10px 0;
	}

	.alert-summary {
		margin-bottom: 0;
	}

	.close-button-bar {
		float: right;
		font-size: 30px;
		line-height: 30px;
		> a {
			color: @black;
		}
	}
}

#header {
	margin: 0;
	padding: 0 15px;
	position: relative;
	border-bottom: 5px rgba(red(@stp-blue), green(@stp-blue), blue(@stp-blue), .8) solid;

	.container {
		// padding: 0;
	}

	.nav-logo {
		padding: 10px 0;
	}

	.header-logo {
		display: block;
		padding: 10px 15px;
		width: 220px;
		height: 80px;
		z-index: 110;

		&.logo-global {
			background: url('/images/svgs/logo-global.svg') left center no-repeat;
			background-size: contain;
		}

		&.logo-boys-prep {
			background: url('/images/svgs/logo-boys.svg') left center no-repeat;
			background-size: contain;
		}

		&.logo-pre-prep {
			background: url('/images/svgs/logo-pre-prep.svg') left center no-repeat;
			background-size: contain;
		}

		&.logo-girls-prep {
			background: url('/images/svgs/logo-girls.svg') left center no-repeat;
			background-size: contain;
		}

		&.logo-college {
			background: url('/images/SPC_Logo_L-Colour.jpg') left center no-repeat;
			background-size: contain;
		}

		&.logo-prep-foundation {
			background-image: url('/images/foundation-logo.png');
			background-repeat: no-repeat;
			background-position: left center;
			background-size: contain;
		}
	}

	.new-slogan {
		color: #fff;
		font-size: 13px;
		font-weight: 700;
		padding: 12px 20px;
		border-left: 1px solid #fff;
		margin-right: auto;
		margin-left: 15px;
	}

	.mobile-header-actions {
		// float: right;
		margin: 5px 0 5px auto;

		.menu-toggle, .search-toggle {
			background-color: transparent;
			height: 60px;
			width: 60px;
			border: 0;
			font-size: 35px;
			margin: 0;
			padding: 0px 10px;
			border-radius: 0;
			border: 0;
		}

		.search-toggle {
			color: rgba(red(@stp-blue), green(@stp-blue), blue(@stp-blue), .8);
		}

		.menu-toggle {
			background-color: @stp-red;
			color: @white;
			// margin-left: auto;
			// position: absolute;
			// bottom: 7px;
			// margin: auto;
			margin-right: 10px;
		}

		.search-toggle, .menu-toggle {
			&:focus {
				outline: none;
			}
		}
	}

	.portal-menu {
		margin: 0;
		padding: 0;
		list-style: none;
		// display: none;
		// float: right;
		position: relative;
		display: flex;
		width: 100%;
		@media (min-width: @screen-md) {
			width: auto;
		}
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		// top: 50%;
		// -webkit-transform: translateY(-50%);
		// -moz-transform: translateY(-50%);
		// -ms-transform: translateY(-50%);
		// -o-transform: translateY(-50%);
		// transform: translateY(-50%);

		li {
			list-style: none;
			// float: left;
			// width: 120px;
			display: flex; align-items: baseline; flex-wrap: wrap; width: auto; border: 0;
			border-right: 1px @stp-blue solid;

			&.search {
				border-right: none;
			}

			a {
				color: @stp-blue;
				text-align: center;
				display: block;
				text-transform: uppercase;
				font-size: 11px;
				padding: 5px 10px;

				i {
					display: block;
					font-size: 13px;
					margin-bottom: 5px;
				}

				&:hover {
					color: @stp-red;
					text-decoration: none;
				}
			}
		}
	}

	&.header-global {
		background: @stp-blue;
		border-bottom: 0px;

		.mobile-header-actions {
			.search-toggle {
				display: none;
			}
		}

		.portal-menu {
			li {
				border-color: @white;

				a {
					color: @white;

					&:hover {
						color: @stp-bluegray-lighter;
						text-decoration: none;
					}
				}
			}
		}
	}



	@media (min-width: @screen-md) {
		border-bottom: 0px;

		// .container {
		// 	padding-left: 15px;
  		// 	padding-right: 15px;
		// }

		.header-inner {
			// height: 130px;
		}

		.header-logo {
			width: 275px;
			height: 110px;
			margin: 5px 0;
			background-position: left center !important;
		}

		.mobile-header-actions {
			display: none;
		}

		.portal-menu {
			// display: block;
			// float: left;
		}
	}
}

#navigation {
	// background-color: lighten(@stp-bluec, 25%);
	// background-color: rgba(red(@stp-blue), green(@stp-blue), blue(@stp-blue), .8);
	background-color: @stp-blue;
	display: none;

	.navbar-default {
		margin: 0 -15px;
		border: 0;
		background: transparent;

		.navbar-nav {
			margin: 0 -15px;
			> li {
				> a {
					font-weight: normal;
					text-transform: uppercase;
					color: @white;

					&.active, &.active_parent {
						background-color: @stp-blue;
						color: @white;
						font-weight: 700;
					}
					@media (min-width: @screen-md) {
						padding: 22px 25px !important;
					}
				}
			}
		}
	}

	&.navigation-global {
		background-color: @white;

		.navbar-default {
			.navbar-nav {
				> li {
					> a {
						color: @stp-blue;
						padding-left: 15px;
    					padding-right: 15px;

						&.active, &.active_parent {
							color: @white;
							background-color: @stp-blue;
						}
					}
				}
			}
		}

		@media (min-width: @screen-lg) {
			.navbar-default {
				.navbar-nav {
					> li {
						> a {
							padding-left: 25px;
    						padding-right: 25px;
						}
					}
				}
			}
		}
	}

	&.navigation-girls-prep, &.navigation-boys-prep {
		.navbar-default {
			.navbar-nav {
				> li {
					> a {
						padding-left: 25px;
  						padding-right: 25px;
  						font-size: small;
					}
				}
			}
		}

		@media (min-width: @screen-lg) {
			.navbar-default {
				.navbar-nav {
					> li {
						> a {
							padding-left: 25px;
							padding-right: 25px;
							font-size: small;
						}
					}
				}
			}
		}
	}

	&.navigation-college {
		.navbar-default {
			.navbar-nav {
				> li {
					> a {
						padding-left: 12px;
						padding-right: 12px;
						font-size: small;
					}
				}
			}
		}

		@media (min-width: @screen-lg) {
			.navbar-default {
				.navbar-nav {
					> li {
						> a {
							padding-left: 20px;
							padding-right: 20px;
							font-size: small;
						}
					}
				}
			}
		}
	}

	&.navigation-prep-foundation {
		.navbar-default {
			.navbar-nav {
				> li {
					> a {
						padding-left: 14px;
    					padding-right: 14px;
					}
				}
			}
		}

		@media (min-width: @screen-lg) {
			.navbar-default {
				.navbar-nav {
					> li {
						> a {
							padding-left: 27px;
    						padding-right: 27px;
						}
					}
				}
			}
		}
	}

	@media (min-width: @screen-md) {
		display: flex !important;
		.navbar.navbar-default .navbar-nav {
			display: flex !important;
		}
	}
}

// Change navbar toggle max media screen width
@media (max-width: @screen-md) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }
}

#myNavmenu {

	&.navmenu-global, &.navmenu-college {
		.navmenu-portal-links {
			> li {
				width: 32%;
				float: left;
				margin: 2px;

				&:first-child {
					border-right: 1px @stp-bluegray-lighter solid;
				}
			}
		}
	}

	.navmenu-portal-links {
		display: block;
		border: 1px @stp-bluegray-lighter solid;
		margin: 15px;
		background-color: @white;

		padding: 0px;
		list-style: none;

		> li {
			line-height: 1.1;

			a {
				color: @stp-blue;
				text-transform: uppercase;
				line-height: 1;
				font-size: 12px;
				display: block;
  				padding: 5px;

				i {
					margin: 0 7.5px 5px 0;
					display: block;
					float: left;
				}
			}
		}
	}

	.navmenu-nav {
		margin-bottom: 75px;

		> li {
			> a {
				border-bottom: 1px @stp-bluegray-lighter solid;
				font-weight: bold;
				text-transform: uppercase;
			}

			.dropdown-menu {
				margin-bottom: 0px;
				position: relative;

				> li {
					> a {
						border-bottom: 1px @stp-bluegray-lighter solid;
						font-weight: normal;
						padding: 10px 15px 10px 25px;
					}
				}
			}
		}
	}

	.navmenu-site-switcher {
		position: relative;
		top: -75px;
		margin: 0;
  		padding: 0;
  		list-style: none;

		> li {
			width: 33%;
			float: left;
			padding: 2px;

			a {
				display: block;
				font-size: 12px;
				line-height: 1;
				text-transform: uppercase;
				font-weight: bold;
				color: @white;
				text-align: center;
				padding: 7.5px;

				&.girls {
					background-color: @stp-red;
				}
				&.boys {
					background-color: @stp-navy;
				}
				&.college {
					background-color: @stp-yellow;
					line-height: 2;
				}
				&.foundation {
					background-color: #999;
					line-height: 2;
				}
			}
		}
	}
}

#banner-carousel {
	.overlay-bar {
		height: 8px;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		position: absolute;
		top: 0;
		z-index: 100;
	}

	.school-logo {
	  height: 20%;
	  width: 25%;
	  position: absolute;
	  bottom: 0;
	  right:0;
	  z-index: 100;

	  @media (max-width: @screen-sm) {
	  	display:none;
	  }
	}


	.slick-next, .slick-prev {
		height: 55px;
  		width: 30px;
  		margin-top: -27.5px;
	}
	  

	.slick-next:before, .slick-prev:before {
		font-size: 55px;
		font-family: 'FontAwesome';
		line-height: 1;
		color: @white;
		text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
		margin: 0 5px;
	}

	.slick-next {
		right: 15px;
		.fa;

		&:before {
			content: @fa-var-angle-right;
		}
	}

	.slick-prev {
		left: 15px;
		.fa;

		&:before {
			content: @fa-var-angle-left;
		}
	}

	.carousel-indicators {
		> li {
			height: 14px;
			width: 14px;
			margin: 5px;
			border-color: @stp-blue;

			&.active {
				background-color: @stp-blue;
			}

		}

	}

	.banner-carousel-inner {
		overflow: hidden;

		.item {
			background-repeat: no-repeat;
			background-size: cover;
			height: 240px;

			&:not(.slick-slide):not(:first-child) {
				display: none;
			}

			.caption {
				font-weight: 800;
				color: @white;
				text-shadow: -1px 2px 5px rgba(0, 0, 0, 0.5);
				display: none;
			}

			@media (min-width: @screen-xs) and (max-width: @screen-xs-max) {
				height: 320px;
			}

			@media (min-width: @screen-sm) {
				height: 440px;

				.caption {
					display: block;
					font-size: 24px;
				}
			}

			@media (min-width: @screen-md) {
				height: 550px;

				.caption {
					font-size: 25px;
				}
			}

			@media (min-width: @screen-lg) {
				height: 660px;

				.caption {
					font-size: 36px;
				}
			}
		}
	}
}

.carousel {
	.carousel-control {

		&.left, &.right {
			background-image: none;

			> span {
				position: absolute;
				top: 50%;
				width: 30px;
				height: 30px;
				margin-top: -15px;
				background: rgba(0, 0, 0, 0.5);
				padding: 5px;
			}
		}
		
		&.left {
			> span {
				margin-left: -15px;
				left: 50%;
			}
		}

		&.right {
			> span {
				margin-left: -15px;
				left: 50%;
			}
		}
	}
}



#home-carousel {
	margin-bottom: 40px;

	.item {
		background-repeat: no-repeat;
		background-size: cover;
	}

	.carousel-control {
		width: 50px;
		z-index:99;
	}

	.carousel-caption {
		top: 0;
		left: auto;
		right: 0;
		width: 100%;
		height: 100%;
		padding: 10px 50px;
		background: rgba(0, 0, 0, 0.5);

		.carousel-content {
			top: 50%;
			position: relative;
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			transform: translateY(-50%);
		}

		.carousel-title {
			font-size: 18px;
			margin: 0px 10% 20px 10%;
		}

		.carousel-cta {
			margin: 20px;

			.btn-social {
				color: @white;
			}

			.btn-primary {
				background: transparent;
				border-color: @white;
				color: @white;
			}
		}
	}

	@media  (min-width: @screen-xs) and (max-width: @screen-xs-max) {
		.carousel-caption {	
			.carousel-description {
				display: block;
			}
		}
	}


	@media (min-width: @screen-sm) {
		.carousel-caption {	
			width: 75%;

			.carousel-description {
				display: block;
			}
		}
	}

	/* Medium devices (desktops, 992px and up) */
	@media (min-width: @screen-md) {
		.carousel-caption {	
			width: 50%;
		}
	}
}

.box-title {
	position: relative;
	padding-bottom: 30px;
	// &:after {
	//     position:relative;
	//     display:inline-block;
	// }
	&:after {
	    content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 10px auto;
		width: 10%;
		height: 6px;
		background: #ee1d24;
	}
}
.page-heading .fancy-heading h1 {
	font-size: 41px;
	text-transform: none;
	margin-bottom: 12.5px;
}

#cta-pane {
	padding: 12.5% 0 30px; 
}
.footer-contacts {
	padding: 10.5% 0 10px;
	.d-flex .px-1.mt-1.mx-auto {
		@media (min-width: @screen-md) {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
	}
}

@media (min-width: @screen-md) {
	#cta-pane {
		clip-path: polygon(50% 27.5%, 100% 0, 100% 100%, 0 100%, 0 0);
		+ .footer-contacts {
			clip-path: none;
			padding: 45px 0 10px;
		}
	}
	.footer-contacts {
		clip-path: polygon(50% 10.5%, 100% 0, 100% 100%, 0 100%, 0 0);
	}
	.clipping {
		z-index: 1;
		.clip-this {
			&:first-of-type {
				clip-path: polygon(100% 22.5%, 100% 100%, 0 100%, 0 0);
			}
			&:nth-of-type(2) {
				clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 22.5%);
			}
		}
		.clip-bottom {
			// clip-path: polygon(0 0,100% 0,100% 85%,50% 100%,0 85%);
			clip-path: polygon(100% 0, 100% 70.5%, 50% 100%, 0 70.5%, 0% 0%);
			margin-bottom: -15.5%;
		}
		&.pane-feature {
			// background-color: transparent;
			position: relative;
			z-index: 2;
		}
		&.pane-promo {
			padding: 0 !important;
			background-color: inherit;
			.clip-this {
				background-color: inherit;
			}
		}
	}
	&.pane-promo {
		padding: 0 !important;
		background-color: inherit;
		.clip-bottom {
			margin-bottom: @line-height-computed * 3px;
		}
		.clip-this {
			background-color: inherit;
		}
	}
	&.pane-peek {
		.peek-heading {
			// margin-top:38%;
		}
	}
}


.box {
	position: relative;

	img {
		border-radius: 0;
	}

	&.box-welcome {
		padding-top: 28px;

		&.box-welcome-foundation {
			padding-bottom: 0px;
		}

		&.box-welcome-home {
			text-align: center;
			border-bottom: none;
			padding: 90px 45px 30px 45px;
			background-color:#023B65;
			// border-bottom:1px solid yellow;
			color:#fff;

			h1.box-title {
				// border-bottom: 0px;
				// margin-bottom: 0px;
			}

		}

		.box-summary {
			font-size: 20px;
		}
	}

	&.box-cta {
		.call-to-action {
			position: relative;

			&:hover, &:hover, &:focus {
				text-decoration: none;
			}

			img {
				display: none;
			}

			.btn {
				margin: 0 auto;
				display: block;
				width: 100%;
				position: relative;
				font-weight: bold;
				margin-bottom: 12px;
			}
		}
	}

	&.box-schools {
		padding-top: 28px;
		padding-bottom: 28px;
	}

	&.box-promo {
		border-top: 1px @stp-bluegray-lighter solid;
		padding-top: 14px;
		padding-bottom: 14px;
	}

	&.box-news {
		padding-bottom: 14px;
	}

	&.box-video {
	
		.row {
			padding-top: 28px;
			padding-bottom: 28px;
		}

		h2 {
			font-size: @font-size-base;
			font-family: @font-family-sans-serif;
			font-weight: bold;
		}
	}

	@media (min-width: @screen-sm) {
		&.box-cta {
			.call-to-action {
				img {
					display: block;
				}

				.btn {
					background-color:#023b65;
					width: 100%;
					margin-bottom: 0;
					top: 0;
					border-radius:0;
					border-color:#023b65;
				}
			}
		}

		&.box-schools {
			padding-top: 0;
			padding-bottom: 0;
		}

		&.box-promo {
			padding-top: 45px;
			padding-bottom: 45px;
		}

		&.box-news {
			padding-bottom: 45px;

			h1 {
				// font-size: 23px;
				text-align: center;
				padding-bottom: 45px;
			}
		}

		&.box-video {
			.row {
				padding-top: 90px;
				padding-bottom: 90px;
			}
		}


	}

	@media (min-width: @screen-md) {

	}

	@media (min-width: @screen-lg) {

	}
}

.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.pane-community {
	background-color:#F0F0F0;
	padding:80px 0;
}

.media-body {
	width: auto;
}

.clip-this {
	.media-body {
		opacity: 0;
		transition: .3s opacity ease-in-out;
	}
	&:hover {
		.media-body {
			opacity: 1;
		}
	}
}

.pane-promo {
	.media-body {
		position:absolute;
		top:40%;
		left:2%;
		margin:20px;
		background: rgba(2, 59, 101, 0.85) !important;
		color: #fff;
		width:90%;
		padding:30px;
		text-align: center;
	}
	.media-heading {
		margin-bottom: 20px;
		a {
		    font-family: @font-family-serif;
		    font-size: 32px;
		    font-weight: 700;
		    text-transform: uppercase;
		    color: #fff !important;
			text-transform: capitalize;
			small {
				color: #fff !important;
				font-size: .67em;
			}
		}
	}
	.readmore-wrapper {
		position: relative;
	    transform: translateY(-50%);
	    text-align: center;
		a.read-more {
				display: inline-block;
			    padding: 12px 45px;
			    /* left: 35%; */
			    /* right: 0; */
			    text-align: center;
			    // width: 35%;
			    margin: 0 auto;
		}
	}
}

.pane {
	position: relative;

	img {
		border-radius: 0;
	}

	.pane-title {
		font-family: @font-family-sans-serif;
		text-transform: uppercase;
		text-align: center;
		font-size: 28px;
		margin-bottom: 28px;
	}

	h2 {
		font-size: @font-size-base;
		font-family: @font-family-sans-serif;
		font-weight: bold;
	}

	&.pane-video, &.pane-news, &.pane-promo {
		padding-top: 28px;
		padding-bottom: 28px;
		background-color: @stp-bluegray-lighter;
		&.clipping {
			background-color: inherit;
		}

		.media {
			margin-bottom: 0px;
			padding-bottom: 0px;
		}
		&.bg-none {
			background-color: transparent;
		}
	}

	&.pane-news {
			background-color:#fff;

	}

	h1 {

		text-align: center;
	}

	@media (min-width: @screen-sm) {
		.pane-title {
			margin-bottom: 45px;
		}

		&.pane-news {
		 	padding-top: 90px;
			padding-bottom: 90px;
		}

		&.pane-video, &.pane-promo {
			padding-top: 90px;
			padding-bottom: 90px;

			h2 {
				margin-top: 0;
			}
		}


	}
}




.btn-warning {
	border-radius:0;
	padding:10px 25px;
	font-weight: bold;
	margin:10px 0 10px 0;
	color:#333 !important;
}

.pane-logos {
	background-color:#fff;
	padding:140px;
	overflow: hidden;
	padding-top:200px;
	color: #fff;
}

.center-text {
	text-align: center;
}

#video-modal {
	padding: 2% !important;

	.modal-dialog {
		width: 100%;
		height: 100%;
		margin: 0;
	}

	.modal-content {
		width: 100%;
		height: 100%;
	}
}

#cta-pane {
	background-size: cover;
	// padding: 70px 0;
	position: relative;
	margin-bottom: -1px;

	&.cta-pane-girls-prep {
		background: @stp-blue url('/assets/images/duo-tone-footer-girls.jpg') center 8% no-repeat;
	}

	&.cta-pane-boys-prep {
		background: @stp-blue url('/assets/images/duo-tone-footer-boys.jpg') center 76% no-repeat;
	}

	&.cta-pane-pre-prep {
		background: @stp-blue url('/assets/images/duo-tone-footer-boys.jpg') center 76% no-repeat;
	}

	&.cta-pane-college {
		background: @stp-blue url('/assets/images/duo-tone-footer-college.jpg') center 73% no-repeat;
	}

	&.cta-pane-prep-foundation {
		background: @stp-blue url('/assets/images/duo-tone-footer-foundation.jpg') center 70% no-repeat;
	}

	.item {
		text-align: center;
		margin-bottom: 15px;

		.cta {
			// background-color: @white;
			// display: inline-block;
			// line-height: 1;
			// border-radius: 50%;
  	// 		padding: 30px;
  	// 		width: 110px;

			i {
				font-size: 50px;
				color: @stp-blue;
			}

			&:hover {
				background-color: @stp-red;

				i {
					color: @white;
				}

			}
		}

		.desc {
			padding-top: 10px;
			color: @white;
			font-weight: bold;
			font-size: 1.085rem;
			// text-transform: uppercase;
		}
	}
}

#by-numbers {
	background-color: rgba(red(@stp-blue), green(@stp-blue), blue(@stp-blue), .3);
	// padding: 5px 0;
	color: @stp-blue;
	position: relative;

	.container {
		padding: 30px 0;

		.item {
			text-align: center;
			padding-top: 5px;
			padding-bottom: 5px;

			.number {
				font-size: 52px;
				font-weight: bold;
			}
			.desc {
				p {
					margin-bottom: 0px;
				}
			}
		}

		.col-bdr:after {
			border-color: @stp-blue;
		}
	}
}

#footer {
	position: relative;
}

#whois-footer {
	padding: 15px 0;
	font-size: 12px;
}

.footer-whois {
	padding: 15px 0;
	font-size: @font-size-small;
	margin-bottom: 15px;
}

.bout-pane {
	background-color:#fff;
	.triangle-down {
	    &:after {
			  border-top:139px solid #fff;
			  border-left: 50vw solid transparent;
			  border-right: 50vw solid transparent;
			  width: 0;
			  height: 0;
			  top: 0;
			  content: "";
			  display: block;
			  position: absolute;
			  overflow: hidden;
			  left: 0;
			  right: 0;
			  margin: auto;
			  z-index:9;
		}
	}
	.media-body {
		h2 {
			color:#fff;
		}
		.desc {
			background: rgba(255, 255, 255, 0.65) !important;
		}
	}
}

.footer-contacts {
	background: @stp-blue;
	margin-top: 0px;
	font-size: 12px;
	color: @white;
	position: relative;

	h2 {
		text-transform: uppercase;
		margin-top: 0;
		font-size: 18px;
	}

	.school-logo {
		margin-bottom: 10px;
	}

	a {
		color: @white;
		&:hover {
			color: @white;
		}
		&.btn-edit-this {
			color: @stp-blue;
			&:hover {
				color: @stp-blue;
			}
		}
	}

	// &.pre-prep,
	// &.girls-prep,
	// &.boys-prep,
	// &.college {
	// 	padding: 40px 0 30px;
	// 	&:before {
	// 		display:none !important;
	// 	}
	// }   
}

#main {
}

// Main menu
.navbar-default {
	margin-top: 20px;
}
/* ========================================= */

/**	----------------------------------------
/**	2. Modular components
/** Modular blocks & re-usable components
/**	----------------------------------------*/

// Media Cntd.
// ------------------------------------
.media-summary {
	p {
		&:nth-of-type(n+2) {
			display: none;
		}
	}
}
.media {
	padding-bottom: 28px;
	// border-bottom: 1px @stp-bluegray-lighter solid;
	margin-bottom: 28px;
	position: relative;

	&:last-child {
		border-bottom: 0;
		margin-bottom: 0;
		padding-bottom: 0;
	}

	.media-img {
		img {
			border-radius: 0;
		}
	}

	.media-heading {
		font-family: @font-family-sans-serif;
		font-size: @font-size-base;
		font-weight: bold;
		text-transform: uppercase;

		small {
			font-size: 12px;
			color: @text-color;
			font-weight: bold;
		}

		a {
			color: @text-color;
		}
	}

	.media-body {
		.media-meta {
			text-transform: uppercase;
			font-size: 11px;
			margin-bottom: 0;

			&:after {
				display: none;
				content: '';
				background-color: @stp-bluegray-lighter;
				height: 1px;
				width: 100%;
				margin-top: 12px;
			}

			.label {
				font-size: 9px;
				border-radius: 3px;
				font-weight: normal;
				display: inline-block;
			}
		}

		.media-summary {
			margin-bottom: 15px;
			display: none;
		}

		.media-cta {
			margin-bottom: 15px;

			.btn {
				font-size: 12px;
			}
		}
	}

	.pull-left {
		padding: 0;
	}

	@media (max-width: @screen-xs-max) {
		.media-body {
			.media-heading {
				margin-top: 0;
			}

			.media-cta {
				display: none;
			}
		}

		&.media-head-primary {
			border-bottom: 1px @stp-bluegray-lighter solid;
		}
	}

	@media (min-width: 620px) {
		padding-bottom: 45px;
		margin-bottom: 45px;

		.media-body {
			.media-meta {
				margin-bottom: 25px;

				&:after {
					display: block
				}
			}

			.media-summary {
				display: block;
			}
		}
	}

	&.media-head-primary, &.media-head-secondary, &.media-head-tertiary {
		margin-bottom: 28px;
		//padding-bottom: 28px;

		.media-img {
			margin-right: 15px;
			img {
				border-radius: 0;
			}
		}

		.media-body {
			.media-summary {
				display: block !important;
				margin-bottom: 0px;
				font-size: 13px;
			}
		}
	}

	&.media-head-secondary:last-child {
		margin-bottom: 0;
		padding-bottom: 10px;
	}
}

.welcome-feature{
	img {
		border-radius:0;
	}

		.media-head-secondary{
		    margin-bottom: 0;
		    padding-bottom: 90px !important;
		}
	
	.head-wrapper {
		text-align: center;
		position:absolute;
		left:0;
		right:0;
		z-index: 999;
		bottom: -10px;
		.bg-red-heading {
			background-color: #ef1e24;
			padding: 18px 30px;
			width: 280px;
			min-height: 111px;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			line-height: 1.5em;


			a, small {
				color: #fff !important;
				font-size: 18px !important;
				text-transform: capitalize;
			}
		}
	}
}

.pane-feature {

		img {
			// margin-bottom: -15%;
		}
}

.pane-peek {
	padding:0;
	background-color: #023B65;
	overflow: hidden;
	align-items: flex-end !important;
	.desc {
		padding:10px 40px;
		text-align: center;
		color: #fff !important;
		font-size:16px;
	}
	img {
		align-self: flex-end;
	}

	.peek-heading {
		color: #fff !important;
	}
	.btn-warning {
		padding:10px 25px;
		color: #fff !important;
		margin-top:30px !important;
	}

}

@media (max-width: @screen-xs-max) {
	.col-boy {
		.media.media-head-tertiary {
			padding-bottom: 28px;
			margin-top: 24px;
			margin-bottom: 28px;
			border-bottom: 1px @stp-bluegray-lighter solid;
		}
	}
}

// Listing
// ------------------------------------

.listing {
	.listing-img {
		img {
			border-radius: 0;
		}
	}

	.listing-heading {
		font-family: @font-family-sans-serif;
		font-size: 15px;
		font-weight: bold;
		text-transform: uppercase;
		margin-top: 25px;
		margin-bottom: 2.5px;
		
		a {
			color: @text-color;
		}
	}

	.listing-body {

		.listing-meta {
			text-transform: uppercase;
			font-size: 11px;
			margin-bottom: 0;

			&:after {
				display: none;
				content: '';
				background-color: @stp-bluegray-lighter;
				height: 1px;
				width: 75%;
				margin-top: 10px;
			}

			.label {
				font-size: 9px;
				font-weight: normal;
				display: inline-block;
			}
		}

		.listing-summary {
			margin-bottom: 15px;
			display: none;
		}

		.listing-cta {
			margin-bottom: 15px;

			.btn {
				font-size: 12px;
			}
			a {
				padding:0 !important;
				font-weight:bold !important;
			}
			.fa {
				color: #ed1c24 !important;
			}
		}
	}

	@media (max-width: @screen-xs-max) {
		padding-bottom: 28px;
		border-bottom: 1px @stp-bluegray-lighter solid;
		margin-bottom: 28px;

		.listing-img {
			width: 33%;
			margin-top: 2px;
			float: left;
		}

		.listing-body {
			float: right;
			width: 67%;
			padding-left: 15px;

			.listing-heading {
				margin-top: 0;
			}

			.listing-cta {
				display: none;
			}
		}
	}

	@media (min-width: 620px) {
		.listing-body {
			.listing-meta {
				margin-bottom: 25px;
			}

			.listing-summary {
				display: block;

				&:after {
					display: block;
				}
			}

		}
	}

}

.panel {
	border: 0px;
	box-shadow: none;
	position: relative;

	.panel-heading {
		// font-size: 23px;
		text-transform: uppercase;
		padding: 10px 0px;
		margin-bottom: 5px;
		background-color: transparent;
		* {
			line-height: 0.8;
		}
	}

	&:nth-of-type(n+2) {
		margin-top: 65px;
	}

	.panel-body {
		padding-left: 0;
		padding-right: 0;
	}

	.nav {
		> li {

			a {
				color: #999999;
				border-radius: 0px;
				border-bottom: 1px #F2F2F2 solid;
				// font-size: 13px;
				padding: 16px 0px;

				&:hover {
					background-color: transparent;
					color: @stp-blue;
				}
			}

			&.active, &.active_parent {
				a {
					background-color: transparent;
					color: @stp-red;
				}
			}
		}
	}

	.nav-stacked > li + li {
		margin-top: 0;
		margin-left: 0;
	}

	&.recent-entries {
		.panel-body {

			.media {
				padding-bottom: 0px;
				margin-bottom: 20px;
				border-bottom: 0px;

				.media-heading {
					a {
						color: @stp-red;
					}
				}

				.media-meta {
					margin-bottom: 0;

					&:after {
						display: none;
					}
				}
			}
		}


	}
}

// Metadata at the bottom of an article/page
// ------------------------------------
.meta {
	display: block;
	font-size: 12px;

	border-top: 1px solid @gray-lighter;
	border-top-color: #efefef;
	border-bottom: 1px solid @gray-lighter;

	ul {
		margin: 0;
		list-style: none;
		float: right;
	}
	li {
		float: left;
		text-align: right;
		margin: 5px 0 5px 0;
		padding: 0 2px;
	}

	.cat {
		margin-top: 11px;

		&:before {
			content: '';
			.glyphicon;
			.glyphicon-tag;
			display: inline-block;
			margin-right: 5px;
		}
	}
}

.share-btn {
	.btn();
	
	&-facebook { color: @brand-facebook; &:hover { color: darken(@brand-facebook, 8%); } }
	&-twitter { color: @brand-twitter; &:hover { color: darken(@brand-twitter, 8%); } }
	&-google { color: @brand-google; &:hover { color: darken(@brand-google, 8%); } }
	&-linkedin { color: @brand-linkedin; &:hover { color: darken(@brand-linkedin, 8%); } }
	&-pinterest { color: @brand-pinterest; &:hover { color: darken(@brand-pinterest, 8%); } }
	&-instagram { color: @brand-instagram; &:hover { color: darken(@brand-instagram, 8%); } }

	border-color: #dddddd;
	margin: 5px;
}

.social-share-list {
  .list-inline();
  li {
  	padding-bottom: 5px;
  	padding-top: 5px;
  }
  .social-share {
	.btn();
	.btn-default();
	&.facebook { color: @brand-facebook; &:hover { color: darken(@brand-facebook, 8%); } }
	&.twitter { color: @brand-twitter; &:hover { color: darken(@brand-twitter, 8%); } }
	&.google { color: @brand-google; &:hover { color: darken(@brand-google, 8%); } }
	&.linkedin { color: @brand-linkedin; &:hover { color: darken(@brand-linkedin, 8%); } }
	&.pinterest { color: @brand-pinterest; &:hover { color: darken(@brand-pinterest, 8%); } }
	&.instagram { color: @brand-instagram; &:hover { color: darken(@brand-instagram, 8%); } }

	border-color: #dddddd;
	
	.share-total {
	  margin-left: 5px;
	  color: @black;
	}
  }

  .social-share-stacked {
	&.facebook { .fa-circle { color: @brand-facebook; } &:hover .fa-circle { color: darken(@brand-facebook, 8%); } }
	&.twitter { .fa-circle { color: @brand-twitter; } &:hover .fa-circle { color: darken(@brand-twitter, 8%); } }
	&.google { .fa-circle { color: @brand-google; } &:hover .fa-circle { color: darken(@brand-google, 8%); } }
	&.linkedin { .fa-circle { color: @brand-linkedin; } &:hover .fa-circle { color: darken(@brand-linkedin, 8%); } }
	&.pinterest { .fa-circle { color: @brand-pinterest; } &:hover .fa-circle { color: darken(@brand-pinterest, 8%); } }
	&.instagram { .fa-circle { color: @brand-instagram; } &:hover .fa-circle { color: darken(@brand-instagram, 8%); } }
	.share-total {
	  margin-left: 5px;
	  color: @black;
	}
  }

  margin-bottom: 0px;
}

.breadcrumb {
	padding: 0;
	margin: 10px 15px;
	font-size: 12px; 
	background-color: transparent;
	text-transform: uppercase;
	color: #818587;

	 > li + li:before {
		content: "/\00a0";
		color: @stp-red;
	}

	&:last-child {
		margin-bottom: 0;
	}
}


h1:first-child {
	margin-top: 0;
}

.page-heading {
	margin-top: 35px;
	margin-bottom: 35px;

	h1 {
		font-size: 25px;
		margin: 0;
		text-transform: uppercase;
	}

	.sub-title {
		font-family: @font-family-serif;
		text-transform: uppercase;
		font-size: 19px;
	}

	.media-meta {
	}
}


// Metadata at the top of an article/page ie. published date
// ------------------------------------
.media-meta {
	list-style: none;
	padding: 0;

	margin-bottom: @line-height-base;
	font-size: @font-size-small;
	color: @gray;

	.date {
		font-weight: bold;
	}

	li {
		display: block;
		float: left;
		padding-right: 20px;
	}
	.addthis_toolbox {
		width: 120%;
		.addthis_counter.addthis_bubble_style {
			width: 36px !important;
		}
	}
	.clearfix;
}

/* SEARCH BUTTONS */

#submit {
	background: url('../img/new-search-icon.png');
	width: 20px;
	height: 20px;
	border: none;
}


.listing_grid {
	li {
		margin-right: 20px;
		margin-bottom: 20px;
	}
}

// Video container for inline videos
// -------------------------
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;

	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

// Articles
// -------------------------

.article {
	
	h1, h2, h3, h4, h5, h6 {
		color: @stp-blue;
	}

	// h1 {
	// 	font-size: 20px;
	// }

	// h2 {
	// 	font-size: 18px;
	// }

	// h3 {
	// 	font-size: 16px;
	// }

	// h4 {
	// 	font-size: 15px;
	// }

	// p {
	// 	line-height: 1.8;
	// }

	hr {
		clear: both;
	}

}

.timeline {
	.timeline-item {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		padding: 6% 0;
		position: relative;
		@media (min-width: @screen-md) {
			&:after {
				content: '';
				position: absolute;
				width: 0;
				top: 20%;
				height: 60%;
				left: 50%;
				border-left: 1px solid #D1D1D1;
			}
		}
		> * {
			padding: 0 6%;
			&:last-of-type {
				padding: 5%;
			}
		}

		.summary {
			margin: 10px 0 0 0;
		}
	}
	.timeline-row {
		&:first-of-type {
			.timeline-item {
				padding-top: 0 !important;
			}
		}
		&:nth-of-type(odd) {
			background-color: #F0F0F0 !important;
		}
		&:nth-of-type(even) {
			background-color: #ffffff !important;
		}
		&:last-of-type {
			.timeline-item {
				padding-bottom: 0 !important;
			}
		}
	}
	&.bg-reverse .timeline-row {
		&:nth-of-type(odd) {
			background-color: #ffffff !important;
		}
		&:nth-of-type(even) {
			background-color: #F0F0F0 !important;
		}
	}
}

.sidebar-nav {
	border: 1px #cccccc solid;
	border-bottom: none;
	background: #f3f3f3;
	font-size: 13px;
	text-transform: uppercase;
	font-style: normal;

	&.nav-stacked {
		> li + li {
			margin: 0;
		}
	}

	&.nav-pills {
		> li {
			> a {
				border-radius: 0px;
				font-style: normal;
			}

			&.active, &.active_parent {
				> a, > a:hover, > a:focus {
					background-color: @stp-navy;
					color: @white;
				}
			}
		}
	}

	li {
		border-bottom: 1px #cccccc solid;
		font-style: normal;

		a {
			color: @text-color;
			&.active {

			}
		}
	}
}

// Images
// -------------------------

.img {

}

.article-image {
	padding:0; // We need to override the grid padding on images, e.g. <img class="img article-image col-sm-6">
	margin-top:4px; // adjust to ensure top of image in line with top of text

	&.pull-left {margin-right: 15px;}
	&.pull-right {margin-left: 15px;}

	img {
		border-radius: 0;
	}

	.caption {
		margin-top: 5px;
		font-weight: bold;
		display: block;
		margin-bottom: 10px;

		font-size: @font-size-small;

		p {
			margin-bottom: 0;
			line-height: 13px;
		}
	}
}

// .image-group-grid {

// 	.col-sm-6:nth-child(odd) {
// 		padding-left:0;
// 	}
// 	.col-sm-6:nth-child(even) {
// 		padding-right:0;
// 	}
// 	.clearfix;

// }
// any article images mobile (xs) do not have floats and scale 100%)

@media (max-width: @screen-xs-max) {

	.article-image {
		&.pull-left {float: none; margin: 0;}
		&.pull-right {float: none; margin: 0;}
	}

	.image-group-grid {
		.img {
			float:none;
			margin:0;
			padding:0 !important;
		}
	}
}


// Responsive Video in Jquery Cycle

.cycle-sentinel iframe {
  opacity: 0;
}

// Added as part of responsive video work
// Don't think it should apply generally

.cycle-slideshow {
	
	&.video-slideshow {
		max-width: 770px;
		margin: auto;

		> div { width: 100%; height: 100% }
		> img { width: 100%; height: 100% }
		iframe,object, embed { width: 100%; height: 100% }
	}
	
}

.offerings-slider {
	margin-left: 15px;
	margin-right: 15px;
	
	.slick-slide {
		padding: 10px;
		text-align: center;

		a {
			color: @text-color;

			&.active * {
				opacity: .2;
			}
		}

		.slick-slide-label {
			font-weight: bold;
			text-transform: uppercase;
			margin-top: 10px;
		}
		img.media-object {
		    border-radius: 100%;
		    box-shadow: 2px 1px 3px 2px #d0cccc;
		    // border: 1px solid #ccc;
		}
	}

	@media (min-width: @screen-sm) {
	}
}

// Slick slider
.slick-slider:focus {
	border: 0;
	outline: 0;
	outline: none;
	box-shadow: none;
}
.slick-dots li button:before {
	content: '•';
}

// Read more buttons
// -------------------------
.read-more {
	// text-transform: uppercase;
	// font-weight: bold;
	// &.text-capitalize {
		font-weight: 500;
	// }
}

.archive-link {
	display: block;
	text-align: right;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;
	padding-top:10px;
}

.file-download {
	margin: 0 !important;
	li {
		width: 180px;
		margin-right: 20px;
		margin-bottom: 20px;
		text-align: center;
		background: @gray-light;
		border: 1px solid #CCC;
		padding: 5px;

		img {
			margin: 0 auto;
			display: block;
		}
		.pdf-title {
			font-size: @font-size-base;
			font-family: @headings-font-family;
			font-weight: 700;

			.pdf-icon {
				background: url('../img/pdf-icon.png');
				width: 15px;
				height: 20px;
				margin-right: 5px;
				display: inline-block;
				margin-bottom: -3px;
			}
		}
	}
}


// Pull quotes

.panel.pullquote {
  .quote {
	font-weight:bold;
	display:block;
  }
  .attribution {
	font-style:oblique;
	display:block;
  }

}

// Site Map
// ----------------------
.site-map {
	.site-map-list {
		li {
			width: 80px;
			margin-right: 20px;
			font-size: 20px;
			a {
				color: @text-color;
			}
		}
		li.last_child {
			margin-right: 0;
		}
	}
	.site-map-list-secondary {
		li {
			margin-top: 10px;
			font-size: 16px;
			a {
				color: @gray;
			}
		}
	}
}

/**	----------------------------------------
/**	3. PAGE SPECIFIC
/** Non-modular, non-repeatable elements
/**	----------------------------------------*/

body.content-page.segment-2-contact {
	.article {
		h2 {
			text-transform: uppercase;
			margin-top: 0;
			font-size: 18px;
		}
	}

	#footer {
		.footer-whois {
			margin-bottom: 25px;
			a {
				color: #fc1579;
			}
		}
	}
}

// slick slider
.slick-prev:before, .slick-next:before {
  color: @black;
}

.slider-nav .media-object {
  padding:0 @grid-gutter-width/6;
}


.gallery {
	.caption {
		position: absolute;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.8);
		color: @white;
		width: 100%;
	  	padding: 0 10px;
	}
}

.glyphicon {
	.glyphicon;
}
.glyphicon-list {
	.glyphicon-list;
}

// Ensure we have reasonably tall pages no matter what.

#content {
	// .mb-1();
	padding-bottom: @grid-gutter-width;
	clear: both;
}

body.home #content {
	padding-bottom: 0;
}

#donate {
  .form-group {
    padding-left:0;
  }
}

// timeline

.promo-timeline {
	background-color: #f0f0f0 !important;
	@media(max-width: @screen-sm) {
		padding-top: 0 !important;
	}
}

.tl-timeline p{
  font-size: 16px;
}

.timeline-embed, .tl-timeline {
 
  background-color: transparent !important;
  @media(min-width: @screen-md) {
  	margin-left: 15px;
  }
}

.tl-slide-content-container {
   margin-right: 10px;
   padding-left: 10px;
}

.tl-full-color-background .tl-text .tl-headline-date, .tl-full-color-background .tl-text h3.tl-headline-date {
  //color: red !important;
  //text-shadow: none !important;
  padding-bottom: 10px;
  display: none !important;
}

.tl-text .tl-headline-date, .tl-text h3.tl-headline-date {
  display: none !important;
}

.tl-text, .tl-text p {
  color: @black !important;
}

.tl-timeline .tl-text ul {
  color: @black !important;
}

.tl-slide-content {
  //width: 100% !important;
}

.tl-text, .tl-slide.tl-full-color-background {
  text-shadow: none !important;
}

.tl-timeline h2.tl-headline-title, h2.tl-headline {
  line-height: 38px;
}

h2.tl-headline.tl-headline-title, h2.tl-headline, .tl-slide.tl-full-color-background h2 {
  color: @timeline-title !important;
  font-size: 40px;
}
.tl-slidenav-icon {
	&:before {
	   color: @timeline-title !important;	
	}
}

.tl-full-color-background .tl-text p, .tl-slide.tl-full-color-background p {
    color: @black !important;
  	text-shadow: none !important;
}

.tl-storyslider .tl-slider-container-mask {
	background: #f0f0f0 !important;
}



.partners-logos {
	padding:40px 0;
	overflow: hidden;
	text-align: center;
	border-bottom: 4px solid #ebc608;
}

.red-text {
	color: #ef1e24;
}

.header-ad{
	float: right;
	margin-top: 15px;
	margin-bottom: 25px;

	img{
		width: 80%;
		max-width: 143px; 
	}
}


a.read-more-blue {
	display: block;
	background-color:red;
	color:#fff;
	position:absolute;
	bottom:-20px;
	padding: 10px 40px;
    left: 35%;
    right: 0;
    text-align: center;
    width: 35%;
}

// listing 

.listing-body {
	margin:25px;
}

.listing-meta {
	font-weight:bold;
}

.listing-heading {
	&.text-blue {
		a{
			color:#023b65 !important;
			font-size: 18px !important;
			margin-bottom:10px;
			line-height:24px!important;
			font-weight: normal;
			font-family: @font-family-serif;
		}
	}
}
.text-blue {
	color: @stp-blue !important;
}
.new-slogan.text-blue {
	border-color: @stp-blue !important;
}

.listing-cta {
	margin-bottom: 15px;

	.btn {
		font-size: 12px;
	}
	a {
		padding:0 !important;
		font-weight:bold !important;
	}
	.fa {
		color: #ed1c24 !important;
	}
}

.testimonial-slide {
	.slick-prev {
	    left: -40px;
	}
	.slick-next {
	    right: -40px;
	}

}

.label-danger {
    background-color: @stp-red;
    // border-radius:0;
}

// colours 
.text {
	&-white { color: #fff; }
	&-red { color: @stp-red; }
	&-blue { color: @stp-blue; }
	&-yellow { color: @stp-yellow; }
}

.bg {
	&-white { background-color: #fff; }
	&-blue { background-color: @stp-blue; }
	&-red { background-color: @stp-red; }
	&-yellow { background-color: @stp-yellow; }
	&-light { background-color: #F0F0F0; }
}

// primary button overide
.btn-primary {
	border-radius:0 !important;
	text-transform: none;
	font-weight: bold;
	padding:8px 30px;
}

.chevron-right {
	position: absolute;
	left: 100%;
	top: 8px;
	margin-left: 10px;
	line-height: 0;
	font-size: 25px;
	font-weight: normal;
}

// text transforms
// .text-capitalize {
// 	text-transform: capitalize !important;
// }
.text-transform-none {
	text-transform: none !important;
}
.text-uppercase {
	text-transform: uppercase !important;
}
.text-lowercase {
	text-transform: lowercase !important;
}

// font weights 
.font-weight-normal {
	font-weight: 400 !important;
}
.font-weight-semi-bold {
	font-weight: 600 !important;
}

// card-decks
.card-deck {
	display: flex; 
	flex-wrap: wrap; 
	justify-content: center;
	align-items: stretch;
	.card {
		float: none; 
		display: flex;
		flex-direction: column;
		&-body {
			flex-grow: 1;
		}
		.btn {
			margin: 10px auto 10px 0;
			padding: 7px 30px;
			text-transform: none;
		}
	}
	&.justify-start {
		justify-content: start;
	}
}

// flex
.d-flex {
	display: flex;
	align-items: stretch;
	.nopadding {
		// margin: auto 0 !important;
	}
	&.align-start {
		align-items: start;
		> * {
			margin-bottom: auto !important;
		}
	}
	&.align-center {
		align-items: center;
		> * {
			margin-top: auto !important;
			margin-bottom: auto !important;
		}
	}
	&.align-end {
		align-items: end;
		* > {
			margin-top: auto !important;
		}
	}
	&.flex-wrap {
		flex-wrap: wrap;
	}
	&.justify-center {
		justify-content: center;
	}
}

.listing,
.downloads {
	ol {
		padding: 0 30px;
		counter-reset: my-awesome-counter;
		list-style: none;
		text-align: center;
		li {
			margin: 10px auto;
			text-align: left;
			padding: 0 15px 0 45px;
			background-color: @stp-red;
			counter-increment: my-awesome-counter;
			position: relative;
			width: 620px;
			max-width: 100%;
			&:hover {
				background-color: darken(@stp-red, 10%);
			}
			a {
				color: #fff;
				text-decoration: none;
				padding: 13px 0;
				display: block;
			}
			&:before {
				content: counter(my-awesome-counter)'.';
				color: #fff;
				position: absolute;
				left: 15px;
				top: 12px;
				font-weight: bold;
			}
		}
	}
}

// additionbal icons
.icons {
	padding: 0 0 0 25px;
	font-size: small;
	color: @stp-blue;
	.icon {
		position: absolute;
		width: 20px;
		height: 20px;
		display: inline-block;
		margin: 2px 0 0 -25px;
		background-size: 15px 15px;
		&.location {
			background: url('../images/svgs/location_blue.svg') center left no-repeat;
		}
		&.email {
			background: url('../images/svgs/mail_blue.svg') center left no-repeat;
		}
		&.tel {
			background: url('../images/svgs/telephone_blue.svg') center left no-repeat;
		}
	}
	a {
		color: @stp-blue;
	}
}
.footer-contacts {
	.icon {
		&.location {
			background-image: url('../images/svgs/location.svg');
		}
		&.email {
			background-image: url('../images/svgs/mail.svg');
		}
		&.tel {
			background-image: url('../images/svgs/telephone.svg');
		}
	}
	a {
		color: #fff;
	}
}
.contact-page {
	.footer-contacts {
		display: none;
	}
	.partners-logos {
		border-top: 1px solid lighten(@stp-blue-light, 40%);
	}
}

// forms
// @media (min-width: 768px) {
	#search-pane .navbar-form .input-group,
	.form-inline.search-form .input-group {
		display: flex;
		> * {
			height: 41px;
			padding: 5px 15px;
		}
	}
// }

// Sharing
.share-btn {
	width: 50px;
	height: 50px;
	border-radius: 100%;
	line-height: 27px;
	padding: 10px;
}

.btn.download,
.btn.linked {
	border-bottom: 1px dashed @stp-blue;
	padding: 10px 0;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: 100%;
		height: 0;
		width: 0;
		border-bottom: 1px solid @stp-blue;
		transition: .4s width ease-in;
	}
	&:hover {
		&:before {
			width: 100%;
		}
	}
}
// groups
// .panel-group {
// 	ol { 
// 		list-style: none; 
// 	}
// }




/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

.offerings-list {
	.offerings-item {
		width: 33.33%;
		padding: 8px;
	}
}

@media (min-width: 600px) {
	.offerings-list {
		.offerings-item {
			width: 20%;
		}
	}
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm) {
	#header {
		// margin: 0;
		padding: 10px 0;
	
		.new-slogan {
			font-size: 15px;
			font-weight: 700;
			padding: 15px 30px;
		}
	
		.header-logo {
			display: block;
			padding: 10px 15px;
			// width: 220px;
			height: 110px;
			z-index: 110;
		}
		.portal-menu {
			margin-left: auto;
		}
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md) {
	#header {
		padding: 0;

		.nav-logo {
			padding: 5px 0;
		}
	
		.new-slogan {
			padding: 20px 30px;
			margin-left: 30px;
		}
	
		.header-logo {
			padding: 0;
			width: 250px;
			height: 125px;
		}
	}
	.visible-md-flex {
		display: flex !important;
	}
}

@media (max-width: 992px) {
	.pane.pane-promo.bg-blue.clipping {
		padding-top: 0;
		padding-bottom: 0;
	}
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg) {

}



// navicons
.nav-icons a {
	margin: auto .75rem; 
	text-transform: none !important; 
	span {
		display: block; 
		line-height: 1.4em; 
		margin-top: 8px;
		font-size: 8px;
	}

}

.peek-slider {
	&-01, &-02, &-03, &-04 {
		margin-bottom: 0;
	}
}

a.text-white:hover {
	color: #fff !important;
}

.magazine-slider {
	.fa-angle-left,
	.fa-angle-right {
		position: absolute;
		cursor: pointer;
		top: 33.33%;
	}
	.fa-angle-left {
		right: 100%;
		margin-right: 10px;
	}
	.fa-angle-right {
		left: 100%;
		margin-left: 10px;
	}
}
