/* open-sans-300 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/open_sans/open-sans-v18-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Light'), local('OpenSans-Light'),
         url('../fonts/open_sans/open-sans-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/open_sans/open-sans-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/open_sans/open-sans-v18-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../fonts/open_sans/open-sans-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/open_sans/open-sans-v18-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-regular - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/open_sans/open-sans-v18-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
         url('../fonts/open_sans/open-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/open_sans/open-sans-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/open_sans/open-sans-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/open_sans/open-sans-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/open_sans/open-sans-v18-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
/* open-sans-600 - latin */
@font-face {
     font-family: 'Open Sans';
     font-style: normal;
     font-weight: 600;
     src: url('../fonts//open_sans/open-sans-v18-latin-600.eot'); /* IE9 Compat Modes */
     src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
          url('../fonts//open_sans/open-sans-v18-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts//open_sans/open-sans-v18-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts//open_sans/open-sans-v18-latin-600.woff') format('woff'), /* Modern Browsers */
          url('../fonts//open_sans/open-sans-v18-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts//open_sans/open-sans-v18-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
   }
  /* open-sans-700 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/open_sans/open-sans-v18-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
         url('../fonts/open_sans/open-sans-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/open_sans/open-sans-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/open_sans/open-sans-v18-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/open_sans/open-sans-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/open_sans/open-sans-v18-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
  }

  @font-face {
     font-family: 'Lucida Bright';
     font-style: normal;
     font-weight: normal;
     src: url('../fonts/lucida_bright_demibold/Lucida-Bright-Demibold.eot'); /* IE9 Compat Modes */
     src: local('Lucida-Bright-Demibold'), local('LucidaBright-Demi'),
          url('../fonts/lucida_bright_demibold/Lucida-Bright-Demibold.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/lucida_bright_demibold/Lucida-Bright-Demibold.woff') format('woff'), /* Modern Browsers */
          url('../fonts/lucida_bright_demibold/Lucida-Bright-Demibold.ttf') format('truetype'), /* Safari, Android, iOS */
   }