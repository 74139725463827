/*@import 'https://file.myfontastic.com/n6vo44Re5QaWo8oCKShBs7/icons.css';*/

/* Reset */
.dcsns ul, .dcsns li {
	padding: 0; 
	margin: 0; 
	list-style: none!important;
	background: none;
}

/* Main styles */
/* Icons */
.socicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'socicon';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  font-size: 20px; 
  color: #fff;
}
.socicon:empty{
    width: 1em;
}

[class^="socicon-"]:before,
[class*=" socicon-"]:before {
  font-family: "socicon" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dcsns-li .socicon {
	position: absolute; 
	bottom: 4px; 
	left: 8px; 
	z-index: 3; 
	top: auto;
}

.dcsns-toolbar .filter li a {width: 26px; text-align: center;}

/* Feeds */
.dcsns {
    color: #444;
    list-style: none outside none;
    margin: 0;
	clear: both;
}
.dcsns h1 {
    width: auto;
	position: relative;
}
.dcsns h1, .dcsns h2, .dcsns h3 {
    line-height: 1.35em;
}
.dcsns-content {
	position: relative;
	padding: 0;
}
.dcsns-loading {
	background: url(../images/loading_light.gif) no-repeat 0 0;
	height: 24px;
	padding: 6px 0 0 30px;
	position: absolute;
	top: 10px;
	left: 10px;
}
.dcsns-toolbar .dcsns-loading {
	right: 10px;
	left: auto;
}
.stream {
  margin: 0 auto!important;
}
.stream p {
	margin: 0;
	padding: 0;
}
.stream li a {
	color: #2DB6E8;
}
.stream li {
	background-color: #FFFFFF;
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.5);
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,0.5);
    box-shadow: 0 1px 2px rgba(0,0,0,0.5);
    font-size: 11px;
    padding: 15px 0 35px 0;
    width: 224px;
	position: relative;
	margin: 0 12px 12px 0;

	line-height: 1.35em;
}
.stream li .inner {
	overflow: hidden;
	padding: 0 15px;
}
.stream li .icon {
	position: absolute;
	left: 3px;
	bottom: 0;
	z-index: 3;
}
.stream li .section-intro {
    padding: 7px 0;
	width: 100%;
	left: 0;
	bottom: 0;
	position: absolute;
	color: #fff;
	font-style: normal;
	font-weight: bold;
	font-size: 10px;
	z-index: 2;
}
.stream li .clear {
	clear: both;
}

/* Content blocks */

.stream li .section-intro a {
	text-decoration: underline;
	color: #fff;
	margin-left: 35px;
}
.stream li .section-title {
	display: block;
	font-weight: bold;
	margin-bottom: 3px;
}
.stream li .section-title a {
	color: #555;
}
.stream li .section-title a:hover {
	color: #2DB6E8;
}
.stream li .section-thumb {
	float: left;
	margin: 0 7px 0 0;
}
.stream li .section-thumb img, .stream li .section-text img {
	display: none;
}
.stream li .section-thumb img {
	border: 1px solid #ccc;
	padding: 1px;
	background: #fff;
}
.stream li .section-text {
	display: block;
	margin-bottom: 3px;
	text-align: left;
}
.stream li .section-text br {
	display: block;
}
.stream li .section-user {
	clear: both;
	display: block;
	font-style: italic;
	margin: 0 8px 5px 0;
	display: block;
	float: left;
	padding: 7px 0 0 15px;
	line-height: 12px;
	background: url(../images/profile.png) no-repeat 0 7px;
}
.stream li .meta {
	display: block;
	font-size: 90%;
	color: #999;
}
.stream li .meta span {
	margin: 0 8px 5px 0;
	display: block;
	float: left;
	padding: 0 0 0 15px;
	line-height: 12px;
}
.meta span.shares {
	background: url(../images/shares.png) no-repeat 0 0;
	padding-left: 20px;
}
.meta span.views {
	background: url(../images/views.png) no-repeat 0 0;
	padding-left: 18px;
}
.meta span.likes {
	background: url(../images/likes.png) no-repeat 0 0;
	padding-left: 16px;
}
.meta span.comments {
	background: url(../images/comments.png) no-repeat 0 0;
}
.meta span.users {
	background: url(../images/users.png) no-repeat 0 0;
}
.meta span.videos {
	background: url(../images/videos.png) no-repeat 0 0;
	padding-left: 16px;
}
.stream li .section-meta {
	display: block;
	margin: 0;
	padding: 0;
	clear: both;
}
.stream li .meta.item-comments {
	padding: 0 0 0 35px;
	margin: 0 0 5px 0;
	min-height: 30px;
	position: relative;
	clear: both;
	line-height: 1.3em;
}
.stream li .meta.item-comments img {
	position: absolute;
	left: 0;
	top: 0;
	width: 30px;
}
.stream li .meta.item-likes {
	padding: 0;
	width: 100%;
	overflow: hidden;
	clear: both;
	line-height: 1.3em;
}
.stream li .meta.item-likes img {
	float: left;
	margin: 0 5px 5px 0;
	width: 30px;
	border: 1px solid #ddd;
	padding: 1px;
	background: #fff;
}
.stream li.dcsns-error {
	color: #990000;
}
.stream li .section-share {
	display: block;
	margin: 0 0 5px 0;
	padding: 4px 0 0;
	float: right;
}
.stream li .section-share a {
	display: block;
	width: 16px;
	height: 16px;
	float: left;
	margin: 0 2px 0 0;
	background: url(../images/share.png) no-repeat 0 0;
}
.stream li .section-share a.share-twitter {
	background-position: 0 -16px;
}
.stream li .section-share a.share-reply {
	background-position: 0 -32px;
}
.stream li .section-share a.share-retweet {
	background-position: 0 -48px;
}
.stream li .section-share a.share-favorite {
	background-position: 0 -64px;
}
.stream li .section-share a.share-google {
	background-position: 0 -80px;
}
.stream li .section-share a.share-linkedin {
	background-position: 0 -96px;
}
.stream li .section-share a.share-facebook:hover {
	background-position: -16px 0;
}
.stream li .section-share a.share-twitter:hover {
	background-position: -16px -16px;
}
.stream li .section-share a.share-reply:hover {
	background-position: -16px -32px;
}
.stream li .section-share a.share-retweet:hover {
	background-position: -16px -48px;
}
.stream li .section-share a.share-favorite:hover {
	background-position: -16px -64px;
}
.stream li .section-share a.share-google:hover {
	background-position: -16px -80px;
}
.stream li .section-share a.share-linkedin:hover {
	background-position: -16px -96px;
}

.stream li.dcsns-twitter .section-share {
	display:none;
}
/* Toolbar */
.dcsns-toolbar {
	padding: 0;
	width: 100%;
	overflow: hidden;
	position: relative;
}
/* Filter */
#wall {
	padding-top: 15px;
}
#wall .dcsns-toolbar {
	margin-bottom: 25px;
}
#dcsns-filter.dc-center {
	float: left; 
	padding-left: 50%; 
	display: none;
	margin: 0;
}
.dcsns-toolbar .filter {
	padding: 0;
	margin: 0 0 15px 0;
}
.dcsns-toolbar .filter li {
	display: inline;
	background: none;
	padding: 0;
	margin: 0;
}
.dcsns-toolbar .filter .link-all {
	color: #fff;
	height: 21px;
	padding: 7px 7px 4px 7px;
	text-shadow: 0 0 1px #555;
	font-weight: bold;
}
.dcsns-toolbar .filter li a {
	padding: 4px 3px 0 3px;
	margin: 0 1px 1px 0;
	display: block;
	float: left;
	background: #777;
	height: 28px;
}
.dcsns-toolbar .filter li img {
	margin: 0;
	height: 24px;
	width: 25px;
}
/* Controls */
.dcsns .controls {
	position: absolute;
	right: 0;
	top: 7px;
	height: 22px;
	padding: 0;
	z-index: 12;
}
.dcsns .controls li {
	display: inline;
	background: none;
}
.dcsns .controls a {
	display: block;
	width: 22px;
	height: 22px;
	float: left;
	margin: 0 0 0 2px;
	background: url(../images/controls.png) no-repeat 0 0;
}
.dcsns .controls .prev {
	background-position: -44px 0;
}
.dcsns .controls .next {
	background-position: -66px 0;
}
.dcsns .controls .play {
	background-position: 0 0;
}
.dcsns .controls .pause {
	background-position: -22px 0;
}
.dcsns .controls .prev:hover {
	background-position: -44px -22px;
}
.dcsns .controls .next:hover {
	background-position: -66px -22px;
}
.dcsns .controls .play:hover {
	background-position: 0 -22px;
}
.dcsns .controls .pause:hover {
	background-position: -22px -22px;
}

/* feeds network specific styles */
.stream li.dcsns-rss .section-intro, .filter .f-rss a:hover, .filter .f-rss a.iso-active {
	background-color: #FF9800;
}
.stream li.dcsns-flickr .section-intro, .filter .f-flickr a:hover, .filter .f-flickr a.iso-active {
	background-color: #f90784;
}
.stream li.dcsns-delicious .section-intro, .filter .f-delicious a:hover, .filter .f-delicious a.iso-active {
	background-color: #3271CB;
}
.stream li.dcsns-twitter .section-intro, .filter .f-twitter a:hover, .filter .f-twitter a.iso-active {
	background-color: #4ec2dc;
}
.stream li.dcsns-facebook .section-intro, .filter .f-facebook a:hover, .filter .f-facebook a.iso-active {
	background-color: #3b5998;
}
.stream li.dcsns-google .section-intro, .filter .f-google a:hover, .filter .f-google a.iso-active {
	background-color: #2d2d2d;
}
.stream li.dcsns-youtube .section-intro, .filter .f-youtube a:hover, .filter .f-youtube a.iso-active {
	background-color: #DF1F1C;
}
.stream li.dcsns-pinterest .section-intro, .filter .f-pinterest a:hover, .filter .f-pinterest a.iso-active {
	background-color: #CB2528;
}
.stream li.dcsns-lastfm .section-intro, .filter .f-lastfm a:hover, .filter .f-lastfm a.iso-active {
	background-color: #C90E12;
}
.stream li.dcsns-dribbble .section-intro, .filter .f-dribbble a:hover, .filter .f-dribbble a.iso-active {
	background-color: #F175A8;
}
.stream li.dcsns-vimeo .section-intro, .filter .f-vimeo a:hover, .filter .f-vimeo a.iso-active {
	background-color: #4EBAFF;
}
.stream li.dcsns-stumbleupon .section-intro, .filter .f-stumbleupon a:hover, .filter .f-stumbleupon a.iso-active {
	background-color: #EB4924;
}
.stream li.dcsns-deviantart .section-intro, .filter .f-deviantart a:hover, .filter .f-deviantart a.iso-active {
	background-color: #EB4924;
}
.stream li.dcsns-tumblr .section-intro, .filter .f-tumblr a:hover, .filter .f-tumblr a.iso-active {
	background-color: #365472;
}
.stream li.dcsns-instagram .section-intro, .filter .f-instagram a:hover, .filter .f-instagram a.iso-active {
	background-color: #413A33;
}
.stream li.dcsns-vine .section-intro, .filter .f-vine a:hover, .filter .f-vine a.iso-active {
	background-color: #00BF8F;
}

/* Facebook */
.stream li.dcsns-facebook .section-thumb img {
	max-width: 192px;
	margin-bottom: 5px;
}

.stream li.dcsns-facebook .section-thumb img, .stream li.dcsns-facebook .section-text img {
	display: block;
}

.stream li.dcsns-facebook .section-text img {
	margin: 5px 7px 5px 0;
	float: left;
	border: 1px solid #ddd;
	padding: 1px;
	background: #fff;
	max-width: 192px;
}
.stream li.dcsns-facebook .section-text a {
	margin-right: 4px;
	color: #444;
}
.stream li.dcsns-facebook .section-text-fb a {
	display:none;
}
.stream li.dcsns-facebook .meta {
	display: block;
	clear: both;
}
.stream li.dcsns-facebook .meta.item-comments {
	padding: 0;
	min-height: auto;
}
.stream li.dcsns-facebook .meta.item-comments a {
	display: block;
	margin: 0 0 2px 0;
}
.stream li.dcsns-facebook .share-facebook {
	display: none;
}
/* twitter */
.stream li.dcsns-twitter .section-thumb {
	margin-top: 3px;
}
.stream li.dcsns-twitter .section-thumb img {
	width: 48px; 
	height: 48px;
	display: block;
}
.stream li.dcsns-twitter .section-user {
	padding: 0;
	background: none;
}
.stream li.dcsns-twitter .section-text img {
	margin: 5px 0 0;
	border: 1px solid #ddd;
	padding: 1px;
	background: #fff;
	max-width: 192px;
}
.stream li.dcsns-twitter a {
	color: #38AEAE;
}
.stream li.dcsns-twitter .section-intro a, .stream li.dcsns-twitter .section-intro a:hover {
	color: #fff;
}
.stream li.dcsns-twitter .section-intro span a {
	text-decoration: none;
	margin-left: 0;
}
.stream li.dcsns-twitter .section-intro span a:hover {
	text-decoration: underline;
}
.stream li.dcsns-twitter a:hover {
	color: #38AEAE;
	text-decoration: underline;
}
.stream li.dcsns-twitter .twitter-user {
	margin-bottom: 2px;
	display: block;
}
.stream li.dcsns-twitter .twitter-user a {
	color: #999;
	line-height: 1.35em;
}
.stream li.dcsns-twitter .twitter-user a:hover {
	color: #999;
	text-decoration: none;
}
.stream li.dcsns-twitter .twitter-user a strong{
	color: #444;
}
.stream li.dcsns-twitter .twitter-user a:hover strong {
	color: #38AEAE!important;
	text-decoration: underline;
}
/* google */
.stream li.dcsns-google .section-title {
	margin-bottom: 5px;
}
.dcsns .stream li.dcsns-google .section-thumb img {
	max-width: 192px;
}
.stream li.dcsns-google .meta span.plusones {
	padding-left: 0;
}
/* rss */
.stream li.dcsns-rss .section-thumb img {
	max-width: 192px;
}
.stream li.dcsns-rss .section-text img {
	border: 1px solid #ddd;
	padding: 1px;
	background: #fff;
	max-width: 192px;
}
/* stumbleupon */
.dcsns .stream li.dcsns-stumbleupon .section-title {
	display: block;
	clear: both;
}
.dcsns .stream li.dcsns-stumbleupon .section-thumb img {
	max-width: 192px;
}
/* youtube */
.stream li.dcsns-youtube .section-thumb img {
	max-width: 192px;
}
/* vimeo */
.stream li.dcsns-vimeo .section-thumb img {
	max-width: 192px;
}
.dcsns .stream li.dcsns-vimeo .section-title {
	display: block;
	clear: both;
}
.stream li.dcsns-vimeo .section-text {
	margin-bottom: 5px;
}
.stream li.dcsns-vimeo .section-text .meta {
	padding-top: 5px;
}
.stream li.dcsns-vimeo .logo {
	margin-bottom: 3px;
}
/* Flickr */
.stream li.dcsns-flickr .section-thumb {
	margin: 0;
}
.stream li.dcsns-flickr .section-thumb img{
	max-width: 192px;
}
/* Pinterest */
.stream li.dcsns-pinterest .section-thumb {
	display: block;
}
.stream li.dcsns-pinterest .section-text {
	clear: both;
}
.stream li.dcsns-pinterest .section-thumb img {
	width: 190px;
}
/* dribbble */
.stream li.dcsns-dribbble .inner img {
	margin-bottom: 5px;
	max-width: 192px;
}
/* lastfm */
.stream li.dcsns-lastfm .section-title {
	background: url(../images/tracks.png) no-repeat 0 0;
	padding-left: 17px;
	margin-bottom: 0;
}
/* deviantart */
.stream li.dcsns-deviantart .section-thumb {
	display: block;
	float: none;
}
.stream li.dcsns-deviantart .section-thumb img {
	max-width: 192px;
}
/* tumblr */
.stream li.dcsns-tumblr .section-text img {
	margin: 0 7px 5px 0;
	float: left;
	border: 1px solid #ddd;
	padding: 1px;
	background: #fff;
	max-width: 192px;
}
.stream li.dcsns-tumblr .track {
	display: block;
	background: url(../images/tracks.png) no-repeat 0 5px;
	padding: 5px 0 0 17px;
	margin-bottom: 0;
}
/* instagram */
.stream li.dcsns-instagram {
	padding-bottom: 40px;
}
.stream li.dcsns-instagram .section-title {
	font-weight: normal;
	clear: both;
}
.stream li.dcsns-instagram .section-thumb img {
	margin: 0 7px 5px 0;
	float: left;
	border: 1px solid #ddd;
	padding: 1px;
	background: #fff;
	max-width: 192px;
}
.stream li.dcsns-instagram .meta {
	clear: both;
}
.stream li.dcsns-instagram .section-meta .meta .comments, .stream li.dcsns-instagram .section-meta .meta .likes {
	margin: 0 0 5px 0;
	padding-top: 5px;
	background-position: 0px 5px;
	float: none;
	font-size: 100%;
	font-weight: bold;
}
/* vine */
.stream li.dcsns-vine {
	padding-bottom: 40px;
}
.stream li.dcsns-vine .section-title {
	font-weight: normal;
	clear: both;
}
.stream li.dcsns-vine .section-thumb img {
	margin: 0 7px 5px 0;
	float: left;
	border: 1px solid #ddd;
	padding: 1px;
	background: #fff;
	max-width: 192px;
}
.stream li.dcsns-vine .meta {
	clear: both;
}
.stream li.dcsns-vine .section-meta .meta .comments, .stream li.dcsns-vine .section-meta .meta .likes {
	margin: 0 0 5px 0;
	padding-top: 5px;
	background-position: 0px 5px;
	float: none;
	font-size: 100%;
	font-weight: bold;
}
/* Foursquare */
.stream li.dcsns-foursquare .section-thumb img {
	max-width: 192px;
	margin-bottom: 5px;
	display: block;
}

/* layouts */
/* modern */
.modern .stream li {
	-webkit-box-shadow: 0 0 0 rgba(0,0,0,0.5);
	-moz-box-shadow: 0 0 0 rgba(0,0,0,0.5);
    box-shadow: 0 0 0 rgba(0,0,0,0.5);
	padding: 0;
	width: 260px;
	margin: 0 20px 20px 0;
	font-size: 14px;
	text-align: center;
	word-wrap: break-word;
}
.modern .stream li .inner {
	padding: 0;
}
.modern .stream li .section-thumb {
    margin: 0 0 20px 0;
	display: block;
	float: none;
}
.modern .stream li .section-thumb img {
    max-width: 100%!important;
	width: 100%!important;
	background: none;
    border: none;
    padding: 0;
	margin: 0;
}
.modern .stream li.dcsns-twitter .section-thumb {
	float: left;
	margin: 20px 10px 10px 20px;
}
.modern .stream li.dcsns-twitter .section-thumb img {
    display: block;
    height: 48px!important;
    width: 48px!important;
}
.modern .twitter-user {
	text-align: left!important;
	margin-top: 20px;
}
.modern .twitter-text {
	clear: both;
	display: block;
}
.modern .stream li.dcsns-tumblr .inner {
	padding-top: 20px;
}
.modern .stream li .section-title {
	font-weight: normal;
}
.modern .stream li .section-text, .modern .stream li .section-user, .modern .stream li .section-title {
	padding: 0 20px;
	margin: 0 0 20px 0;
}
.modern .stream li .section-text img {
    max-width: 100%!important;
	width: 100%!important;
	background: none;
    border: none;
    padding: 0;
	float: none;
	margin: 0;
}
.modern .stream li .meta {
	display: none;
}
.modern .stream li .section-user {
	font-size: 12px;
	background: none;
	float: none;
	text-align: center;
}
.modern .stream li .section-intro {
	padding: 10px 0;
	text-align: center;
	font-size: 12px;
	font-weight: normal;
	position: relative;
	display: block;
}
.modern .stream li .section-intro a {
    margin: 0;
    text-decoration: none;
	padding: 0 5px;
}
.modern .stream li .section-intro span {
    font-size: 12px;
}
.modern .stream li .section-intro a.link-user {
    display: block;
}
.modern .stream li .section-intro a.link-user:hover {
    color: #38AEAE;
}
.modern .stream li .section-intro a.link-intro {
    display: none;
}
.modern .stream li .section-user {
	display: none;
}

.modern .stream li .section-share {
	margin: 0 20px 20px 0;
	padding: 0;
}

.modern .stream li .socicon {
	position: absolute; 
	bottom: 15px; 
	left: 15px;
    font-size: 24px;
}

.modern .stream li.dcsns-lastfm .section-title {
	background: url(../images/tracks.png) no-repeat 20px 3px;
	padding-left: 37px;
}
.modern .stream li.dcsns-instagram .section-thumb img {
	margin-bottom: 20px;
}

/* modern - dark*/
.modern.dark .stream li, .modern.dark .stream li .section-user, .modern.dark .stream li .section-intro a, .modern.dark .stream li .section-text a, 
.modern.dark .stream li .section-intro span, .modern.dark .stream li.dcsns-twitter .twitter-user a strong, .modern.dark .stream li .socicon {
	color: #fff;
}
.modern.dark .stream li .section-intro a:hover, .modern.dark .stream li .section-title a:hover, .modern.dark .stream li .section-text a:hover, .stream li.dcsns-twitter .twitter-user a:hover strong {
	color: #4EC2DC;
}
.modern.dark .stream li {
	background-color: #393939;
}
.modern.dark .stream li .section-title a {
    color: #fff;
}
.modern.dark .stream li .section-intro {
	background: #2C2C2C!important;
}
/* modern - light*/
.modern.light .stream li .section-intro a:hover, .modern.light .stream li .section-title a:hover, .modern.light .stream li .section-text a:hover, .stream li.dcsns-twitter .twitter-user a:hover strong {
	/*color: #4EC2DC;*/
	color:#38aeae;
}
.modern.light .stream li {
	background-color: #fff;
}

.modern.light .stream li, .modern.light .stream li .section-intro a, .modern.light .stream li .section-intro span {
    color: #494949;
}
.modern.light .stream li .socicon {
	color: #494949;
}

/*isotope */
.isotope,
.isotope .isotope-item {
  /* change duration value to whatever you like */
  -webkit-transition-duration: 0.8s;
     -moz-transition-duration: 0.8s;
      -ms-transition-duration: 0.8s;
       -o-transition-duration: 0.8s;
          transition-duration: 0.8s;
}

.isotope {
  -webkit-transition-property: height, width;
     -moz-transition-property: height, width;
      -ms-transition-property: height, width;
       -o-transition-property: height, width;
          transition-property: height, width;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
     -moz-transition-property:    -moz-transform, opacity;
      -ms-transition-property:     -ms-transform, opacity;
       -o-transition-property:         top, left, opacity;
          transition-property:         transform, opacity;
}

/**** disabling Isotope CSS3 transitions ****/

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
     -moz-transition-duration: 0s;
      -ms-transition-duration: 0s;
       -o-transition-duration: 0s;
          transition-duration: 0s;
}




.dcsns-toolbar .filter {
	margin-left: -5px;
	li {
		display: inline-block;
		padding-left: 5px;
		.link-all,
		a {
			height: auto;
			display: block;
			padding: @padding-small-vertical/2 @padding-small-vertical;
			background: #fefefe;
			text-align: center;
			text-shadow: none;
			min-width: 26px;
			width: auto;
			border: 1px solid;

			&:hover, &:focus, &.iso-active {
				text-decoration: none;
				color: #fff;
				border-color: @link-color;
			}
		}

		.link-all {
			text-transform: uppercase;
			color: @link-color;
			&:hover, &.iso-active {
				background-color: #ebc70b;
				color: #fff;
			}
		}
	}

	margin-bottom: @grid-gutter-width;
}

.modern.light .stream li{


	font-family: @font-family-base;

	.section-intro {

		text-align: left;
		padding-left: 15px;

		.link-user{
			color: @white;
			font-size: 15px;
			padding: 0;
			font-family: @font-family-serif;
		} 

		a{
			color: #505050;
			padding: 0;
		}
	}

	.fa {

		&.fa-facebook{
			color:#218dfc;
		}

		&.fa-twitter{
			color:#1cd9ea;
		}
	}
}

.modern.light .stream li.dcsns-facebook .section-thumb{
	margin-bottom:0;
}

.stream li .section-share a {
	display: block;
	width: 16px;
	height: 16px;
	float: left;
	margin: 0 2px 0 0;
	background: none;
	text-align: center;
	color: #000;

	&:after {
		.fa-icon();
	}

	&.share {
		&-facebook { 
			&:hover {
				color: @brand-facebook;
			}
			&:after {
				content: @fa-var-facebook;
			}
		}
		&-twitter { 
			&:hover {
				color: @brand-twitter;
			}
			&:after {
				content: @fa-var-twitter;
			}
		}
		
		
		&-google { 
			&:hover {
				color: @brand-google;
			}
			&:after {
				content: @fa-var-google-plus;
			}
		}

		&-linkedin { 
			&:hover {
				color: @brand-linkedin;
			}
			&:after {
				content: @fa-var-linkedin;
			}
		}
	}
}

.social-stream.modern.light .stream li {
	width: 100%;
	margin: 0 0 30px 0;
	padding: 0 15px;
	background-color: transparent;
	// box-shadow: none;


	@media (min-width: @screen-sm) {
		width: 50%
	}

	@media (min-width: @screen-md) {
		width: percentage(1/3);
	}

	@media (min-width: @screen-lg) {
		width: percentage(1/4);
	}


	.inner {
		background-color: #fefefe;
		border-radius: 3px 3px 0 0;
		border: 1px solid @gray-lighter;
		text-align: left;
	}

	.section-intro {

		position: relative;
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 15px;
		color: #444;

	}

	.section-intro + a {
		position: absolute;
		right: 25px;
		bottom: 10px;
		z-index: 2;
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.025);
		font-size: 1.1em;

	}
}

.social-stream .dcsns-content {
	margin-right: -15px;
	margin-left: -15px;
}